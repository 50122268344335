<template>
    <div class="appContentNine">
        <div class="acnBox">
            <!-- 左边信息 -->
            <div class="acnLeftBox">
                <!-- 顶部文字 -->
                <div class="acnTopText">
                    <p class="acnBig wow animate__animated animate__slideInLeft">社交类App</p>
                    <p class="acnSmall wow animate__animated animate__slideInLeft" data-wow-delay="0.1s">
                        支持各种IM场景，沟通联系无所顾忌
                    </p>
                    <p class="acnList wow animate__animated animate__slideInLeft" data-wow-delay="0.2s">
                        定制App开发，支持IOS/安卓双系统
                    </p>
                    <p class="acnList wow animate__animated animate__slideInLeft" data-wow-delay="0.3s">
                        支持商家端/客户端 双端开发
                    </p>
                    <p class="acnList wow animate__animated animate__slideInLeft" data-wow-delay="0.4s">
                        支持App+Pc+Web+微信四合一全网开发
                    </p>
                </div>
                <!-- 图标列表 -->
                <div class="acnBottomList">
                    <div class="ablText">功能</div>
                    <ul>
                        <li class="ablLi wow animate__animated animate__slideInLeft" data-wow-delay="0.5s">
                            <div class="alIcon">
                                <img src="http://iv.vu818.com/img/vu618.png" alt="" class="aiImg" />
                            </div>
                            <!-- <i class="iconfont icon-youxi"></i> -->
                            <p class="ablP">互动游戏</p>
                        </li>
                        <li class="ablLi wow animate__animated animate__slideInLeft" data-wow-delay="0.5s">
                            <div class="alIcon">
                                <img src="http://iv.vu818.com/img/vu618.png" alt="" class="aiImg2" />
                            </div>
                            <!-- <i class="iconfont icon-youxi"></i> -->
                            <p class="ablP">互动游戏</p>
                        </li>
                        <li class="ablLi wow animate__animated animate__slideInLeft" data-wow-delay="0.5s">
                            <div class="alIcon">
                                <img src="http://iv.vu818.com/img/vu618.png" alt="" class="aiImg3" />
                            </div>
                            <!-- <i class="iconfont icon-youxi"></i> -->
                            <p class="ablP">互动游戏</p>
                        </li>
                        <li class="ablLi wow animate__animated animate__slideInLeft" data-wow-delay="0.5s">
                            <div class="alIcon">
                                <img src="http://iv.vu818.com/img/vu618.png" alt="" class="aiImg4" />
                            </div>
                            <!-- <i class="iconfont icon-youxi"></i> -->
                            <p class="ablP">互动游戏</p>
                        </li>
                        <li class="ablLi wow animate__animated animate__slideInLeft" data-wow-delay="0.5s">
                            <div class="alIcon">
                                <img src="http://iv.vu818.com/img/vu618.png" alt="" class="aiImg5" />
                            </div>
                            <!-- <i class="iconfont icon-youxi"></i> -->
                            <p class="ablP">互动游戏</p>
                        </li>
                        <li class="ablLi wow animate__animated animate__slideInLeft" data-wow-delay="0.5s">
                            <div class="alIcon">
                                <img src="http://iv.vu818.com/img/vu618.png" alt="" class="aiImg6" />
                            </div>
                            <!-- <i class="iconfont icon-youxi"></i> -->
                            <p class="ablP">互动游戏</p>
                        </li>
                        <li class="ablLi wow animate__animated animate__slideInLeft" data-wow-delay="0.5s">
                            <div class="alIcon">
                                <img src="http://iv.vu818.com/img/vu618.png" alt="" class="aiImg7" />
                            </div>
                            <!-- <i class="iconfont icon-youxi"></i> -->
                            <p class="ablP">互动游戏</p>
                        </li>
                        <li class="ablLi wow animate__animated animate__slideInLeft" data-wow-delay="0.5s">
                            <div class="alIcon">
                                <img src="http://iv.vu818.com/img/vu618.png" alt="" class="aiImg8" />
                            </div>
                            <!-- <i class="iconfont icon-youxi"></i> -->
                            <p class="ablP">互动游戏</p>
                        </li>
                    </ul>
                </div>
                <!-- 我们的案例 -->
                <div class="acnAlList">
                    <div class="aalText">我们的案例</div>
                    <el-carousel height="100px" arrow="never" :autoplay="true" :interval="2000" indicator-position="none">
                        <el-carousel-item v-for="(item, i) in caseData" :key="i">
                            <ul>
                                <li class="alLi" v-for="el in item" :key="el.id" @click="caseClick(el)">
                                    <img :src="el.logo" class="alImg" />
                                </li>
                            </ul>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <!-- 查看更多案例 -->
                <!-- <div class="acnMoreBtn" @click="goMore">查看更多案例</div> -->
                <div class="ct2More" @click="goMore">
                    <div class="cmTop"></div>
                    <p class="cmp">查看更多案例</p>
                    <div class="cmBottom"></div>
                </div>
            </div>
            <!-- 右边图片 -->
            <div class="acnRightImg">
                <div class="actBg wow animate__animated animate__slideInUp" data-wow-delay="0.2s">
                    <img src="http://iv.vu818.com/img/vu818.png" alt="" />
                </div>
                <img
                    style="height: auto; top: 93px; left: 106px"
                    class="acnImg wow animate__animated animate__slideInUp"
                    src="https://iv.vu818.com/img/page9_1.png"
                    alt=""
                />
                <div class="nineImageBox">
                    <div style="width: 105px; height: 105px; top: 65px" class="imageBoxNine">
                        <img style="bottom: -29px; position: absolute; left: 0" src="http://iv.vu818.com/img/vu618.png" />
                    </div>
                    <div style="width: 73px; height: 65px; top: 200px; left: 50px" class="imageBoxNine">
                        <img style="top: -540px; position: absolute; left: -370px" src="http://iv.vu818.com/img/vu618.png" />
                    </div>
                    <div style="width: 73px; height: 65px; top: 25px; left: 130px" class="imageBoxNine">
                        <img style="top: -540px; position: absolute; left: -639px" src="http://iv.vu818.com/img/vu618.png" />
                    </div>
                    <div style="width: 162px; height: 156px; top: -35px; left: 308px" class="imageBoxNine">
                        <img style="position: absolute; right: 0; bottom: 0" src="http://iv.vu818.com/img/vu1212(2).png" />
                    </div>
                    <div style="width: 73px; height: 65px; top: 200px; left: 333px" class="imageBoxNine">
                        <img style="top: -540px; position: absolute; left: -451px" src="http://iv.vu818.com/img/vu618.png" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AppContentNine",
    props: ["caseData", "caseClick"],

    mounted() {
        function show() {
            let dom = document.getElementsByClassName("nineImageBox");
            let domTop = dom[0].getBoundingClientRect().top;
            // console.log(domTop, "-----------------------");
            if (domTop < 900) {
                dom[0].className = dom[0].className + " " + "scaleClass";
            } else {
                dom[0].className = "nineImageBox";
            }
            window.removeEventListener("scroll", show);
        }
        window.addEventListener("scroll", show);
        this.$AOS.init({
            offset: 200,
            duration: 600,
            easing: "ease-in-sine",
            delay: 100,
        });
        new this.$wow.WOW().init({
            boxClass: "wow",
            animateClass: "animate__animated",
            offset: 0,
            mobile: true,
            live: true,
        });
    },
    methods: {
        goMore() {
            this.$router.push("/case");
        },
    },
};
</script>

<style lang="less" scoped>
.nineImageBox {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
}
@keyframes setScale {
    0% {
        opacity: 0.2;
        scale: 0.2;
    }
    50% {
        opacity: 0.8;
        scale: 1.2;
    }
    100% {
        opacity: 1;
        scale: 1;
    }
}
.scaleClass {
    animation: setScale 3s 1;
}
.imageBoxNine {
    position: absolute;
    overflow: hidden;
    width: 60px;
    height: 60px;
}
.imageBoxNine img {
    width: 45em;
}
.appContentNine {
    width: 100%;
    height: 700px;
    background-color: rgb(253, 209, 61);
    .acnBox {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        // 左边信息
        .acnLeftBox {
            width: 50%;
            height: 90%;
            float: left;
            margin-top: 5%;
            margin-left: 5%;
            // 顶部文字
            .acnTopText {
                width: 80%;
                height: 200px;
                color: white;
                .acnBig {
                    width: 100%;
                    height: 30px;
                    line-height: 30px;
                    font-size: 25px;
                }
                .acnSmall {
                    width: 100%;
                    height: 20px;
                    line-height: 20px;
                    font-size: 15px;
                    margin-top: 5px;
                }
                .acnList {
                    width: 100%;
                    height: 10px;
                    margin-top: 20px;
                    font-size: 12px;
                }
            }
            // 图标列表
            .acnBottomList {
                width: 70%;
                height: 180px;
                margin-top: 20px;
                color: white;
                .ablText {
                    width: 100%;
                    height: 20px;
                    line-height: 20px;
                    font-weight: 900;
                    font-size: 20px;
                }
                ul {
                    width: 100%;
                    height: 130px;
                    margin-top: 20px;
                    display: flex;
                    flex-wrap: wrap;
                    .ablLi {
                        width: 16%;
                        height: 50%;
                        // margin-left: 1%;
                        .alIcon {
                            width: 45px;
                            height: 70%;
                            margin: 0 auto;
                            overflow: hidden;
                            position: relative;
                            .aiImg {
                                position: absolute;
                                top: -339px;
                                left: -772px;
                            }
                            .aiImg2 {
                                position: absolute;
                                top: -339px;
                                left: -824px;
                            }
                            .aiImg3 {
                                position: absolute;
                                top: -339px;
                                left: -872px;
                            }
                            .aiImg4 {
                                position: absolute;
                                top: -339px;
                                left: -929px;
                            }
                            .aiImg5 {
                                position: absolute;
                                top: -339px;
                                left: -980px;
                            }
                            .aiImg6 {
                                position: absolute;
                                top: -339px;
                                left: -1030px;
                            }
                            .aiImg7 {
                                position: absolute;
                                top: -398px;
                                left: -1px;
                            }
                            .aiImg8 {
                                position: absolute;
                                top: -398px;
                                left: -49px;
                            }
                        }
                        .iconfont {
                            width: 100%;
                            height: 50%;
                            font-size: 30px;
                            text-align: center;
                            display: block;
                        }
                        .ablP {
                            width: 100%;
                            height: 20%;
                            text-align: center;
                            font-size: 14px;
                        }
                    }
                }
            }
            // 我们的案例
            .acnAlList {
                width: 70%;
                height: 110px;
                margin-top: 20px;
                color: white;
                .aalText {
                    width: 100%;
                    height: 20px;
                    line-height: 20px;
                    font-weight: 900;
                    font-size: 20px;
                }
                ul {
                    width: 100%;
                    height: 50px;
                    margin-top: 10px;
                    display: flex;
                    justify-content: left;
                    .alLi {
                        width: 51px;
                        height: 51px;
                        cursor: pointer;
                        transition: 0.5s;
                        border-radius: 10px;
                        margin-right: 5px;
                        overflow: hidden;
                        .alImg {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .alLi:hover {
                        transform: translateY(-5px);
                        box-shadow: 1px 1px 10px 1px gray;
                    }
                }
            }
            // 查看更多案例
            // .acnMoreBtn {
            //   width: 150px;
            //   height: 30px;
            //   line-height: 30px;
            //   margin-top: 20px;
            //   text-align: center;
            //   border-radius: 10px;
            //   color: white;
            //   cursor: pointer;
            //   border: 1px solid white;
            //   transition: 0.5s;
            // }
            // .acnMoreBtn:hover {
            //   background-color: #fff;
            //   color: rgb(211, 212, 108);
            // }
            .ct2More {
                width: 160px;
                height: 80px;
                text-align: center;
                margin-top: 10px;
                cursor: pointer;
                font-weight: bold;
                .cmp {
                    width: 100%;
                    height: 30px;
                    line-height: 30px;
                    padding-top: 5px;
                    color: white;
                }
                .cmTop {
                    width: 100%;
                    position: relative;
                    transition: 0.5s;
                }
                .cmTop::before {
                    border-width: 1px 0 0 1px;
                    border-color: white;
                    left: 20px;
                    content: "";
                    position: absolute;
                    width: 7px;
                    height: 7px;
                    border-style: solid;

                    transition-duration: 0.3s;

                    transform: translateZ(0);
                }
                .cmTop::after {
                    border-width: 1px 1px 0 0;
                    border-color: white;
                    right: 20px;
                    content: "";
                    position: absolute;
                    width: 7px;
                    height: 7px;
                    border-style: solid;

                    transition-duration: 0.3s;

                    transform: translateZ(0);
                }
                .cmBottom {
                    width: 100%;
                    position: relative;
                    transition: 0.5s;
                }
                .cmBottom::before {
                    border-width: 0 0 1px 1px;
                    border-color: white;
                    left: 20px;
                    content: "";
                    position: absolute;
                    width: 7px;
                    height: 7px;
                    border-style: solid;

                    transition-duration: 0.3s;

                    transform: translateZ(0);
                }
                .cmBottom::after {
                    border-width: 0 1px 1px 0;
                    border-color: white;
                    right: 20px;
                    content: "";
                    position: absolute;
                    width: 7px;
                    height: 7px;
                    border-style: solid;
                    transition-duration: 0.3s;
                    transform: translateZ(0);
                }
            }
            .ct2More:hover {
                .cmTop {
                    width: 180px;
                    transform: translateY(-10px) translateX(-10px);
                }
                .cmBottom {
                    width: 180px;
                    transform: translateY(10px) translateX(-10px);
                }
            }
        }
        // 右边图片
        .acnRightImg {
            width: 440px;
            height: 420px;
            float: left;
            margin-top: 15%;
            margin-right: 5%;
            position: relative;
            .actBg {
                width: 100%;
                height: 100%;
                position: relative;
                overflow: hidden;
                img {
                    position: absolute;
                    bottom: -470px;
                    right: 35px;
                }
            }
            .acnImg {
                width: 60%;
                height: 90%;
                margin-top: 20px;
                position: absolute;
                top: 0;
                left: 20%;
            }
        }
    }
}
</style>
