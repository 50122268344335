<template>
    <div class="appContentThree">
        <div class="acTBox">
            <!-- 左边信息 -->
            <div class="acTLeftBox">
                <!-- 顶部文字 -->
                <div class="acTTopText">
                    <p class="acTBig wow animate__animated animate__slideInLeft">餐饮类App</p>
                    <p class="acTSmall wow animate__animated animate__slideInLeft" data-wow-delay="0.1s">
                        外卖大战不用“方”,威有科技来帮忙，助您圆满完成送餐任务
                    </p>
                    <p class="acTList wow animate__animated animate__slideInLeft" data-wow-delay="0.2s">
                        定制App开发，支持IOS/安卓双系统
                    </p>
                    <p class="acTList wow animate__animated animate__slideInLeft" data-wow-delay="0.3s">
                        支持商家端/客户端 双端开发
                    </p>
                    <p class="acTList wow animate__animated animate__slideInLeft" data-wow-delay="0.4s">
                        支持对接蓝牙/WIFI打印机
                    </p>
                    <p class="acTList wow animate__animated animate__slideInLeft" data-wow-delay="0.5s">
                        支持App+Pc+Web+微信四合一全网开发
                    </p>
                </div>
                <!-- 图标列表 -->
                <div class="acTBottomList">
                    <div class="ablText">功能</div>
                    <ul>
                        <li class="ablLi wow animate__animated animate__slideInLeft" data-wow-delay="0.6s">
                            <div class="alIcon">
                                <img src="http://iv.vu818.com/img/vu618.png" alt="" class="aiImg" />
                            </div>
                            <!-- <i class="iconfont icon-store"></i> -->
                            <p class="ablP">餐厅加盟</p>
                        </li>
                        <li class="ablLi wow animate__animated animate__slideInLeft" data-wow-delay="0.6s">
                            <div class="alIcon">
                                <img src="http://iv.vu818.com/img/vu618.png" alt="" class="aiImg2" />
                            </div>
                            <!-- <i class="iconfont icon-store"></i> -->
                            <p class="ablP">餐厅加盟</p>
                        </li>
                        <li class="ablLi wow animate__animated animate__slideInLeft" data-wow-delay="0.6s">
                            <div class="alIcon">
                                <img src="http://iv.vu818.com/img/vu618.png" alt="" class="aiImg3" />
                            </div>
                            <!-- <i class="iconfont icon-store"></i> -->
                            <p class="ablP">餐厅加盟</p>
                        </li>
                        <li class="ablLi wow animate__animated animate__slideInLeft" data-wow-delay="0.6s">
                            <div class="alIcon">
                                <img src="http://iv.vu818.com/img/vu618.png" alt="" class="aiImg4" />
                            </div>
                            <!-- <i class="iconfont icon-store"></i> -->
                            <p class="ablP">餐厅加盟</p>
                        </li>
                        <li class="ablLi wow animate__animated animate__slideInLeft" data-wow-delay="0.6s">
                            <div class="alIcon">
                                <img src="http://iv.vu818.com/img/vu618.png" alt="" class="aiImg5" />
                            </div>
                            <!-- <i class="iconfont icon-store"></i> -->
                            <p class="ablP">餐厅加盟</p>
                        </li>
                        <li class="ablLi wow animate__animated animate__slideInLeft" data-wow-delay="0.6s">
                            <div class="alIcon">
                                <img src="http://iv.vu818.com/img/vu618.png" alt="" class="aiImg6" />
                            </div>
                            <!-- <i class="iconfont icon-store"></i> -->
                            <p class="ablP">餐厅加盟</p>
                        </li>
                        <li class="ablLi wow animate__animated animate__slideInLeft" data-wow-delay="0.6s">
                            <div class="alIcon">
                                <img src="http://iv.vu818.com/img/vu618.png" alt="" class="aiImg7" />
                            </div>
                            <!-- <i class="iconfont icon-store"></i> -->
                            <p class="ablP">餐厅加盟</p>
                        </li>
                        <li class="ablLi wow animate__animated animate__slideInLeft" data-wow-delay="0.6s">
                            <div class="alIcon">
                                <img src="http://iv.vu818.com/img/vu618.png" alt="" class="aiImg8" />
                            </div>
                            <!-- <i class="iconfont icon-store"></i> -->
                            <p class="ablP">餐厅加盟</p>
                        </li>
                    </ul>
                </div>
                <!-- 我们的案例 -->
                <div class="acTAlList">
                    <div class="aalText">我们的案例</div>
                    <el-carousel height="100px" arrow="never" :autoplay="true" :interval="2000" indicator-position="none">
                        <el-carousel-item v-for="(item, i) in caseData" :key="i">
                            <ul>
                                <li class="alLi" v-for="el in item" :key="el.id" @click="caseClick(el)">
                                    <img :src="el.logo" class="alImg" />
                                </li>
                            </ul>
                        </el-carousel-item>
                    </el-carousel>
                </div>

                <!-- 查看更多案例 -->
                <!-- <div class="acTMoreBtn" @click="goMore">查看更多案例</div> -->
                <div class="ct2More" @click="goMore">
                    <div class="cmTop"></div>
                    <p class="cmp">查看更多案例</p>
                    <div class="cmBottom"></div>
                </div>
            </div>
            <!-- 右边图片 -->
            <div class="acTRightImg">
                <div class="actBg wow animate__animated animate__slideInUp" data-wow-delay="0.2s">
                    <img src="http://iv.vu818.com/img/vu818.png" alt="" />
                </div>
                <img
                    style="width: 50%; height: auto; top: 120px; left: 110px; z-index: 99"
                    class="acTImg actImg wow animate__animated animate__slideInUp"
                    src="https://iv.vu818.com/img/page3_2.png"
                    alt=""
                />
                <div style="width: 100%; height: 100%" id="logosBox">
                    <div style="top: 160px; left: 67px" class="imageBox">
                        <img style="position: absolute; top: -492px; left: -121px" src="http://iv.vu818.com/img/vu618.png" />
                    </div>
                    <div style="top: 225px; left: 96px" class="imageBox">
                        <img style="position: absolute; top: -489px; left: -217px" src="http://iv.vu818.com/img/vu618.png" />
                    </div>
                    <div style="top: 265px; left: 129px" class="imageBox">
                        <img style="position: absolute; top: -436px; left: -453px" src="http://iv.vu818.com/img/vu618.png" />
                    </div>
                    <div style="top: 58px; left: 245px" class="imageBox">
                        <img style="position: absolute; top: -489px; left: -170px" src="http://iv.vu818.com/img/vu618.png" />
                    </div>
                    <div style="top: 92px; left: 323px" class="imageBox">
                        <img style="position: absolute; top: -970px; left: -223px" src="http://iv.vu818.com/img/vu618.png" />
                    </div>
                    <div style="top: 202px; left: 300px" class="imageBox">
                        <img style="position: absolute; top: -434px; left: -416px" src="http://iv.vu818.com/img/vu618.png" />
                    </div>
                    <div style="top: 161px; left: 289px" class="imageBox">
                        <img style="position: absolute; top: -434px; left: -612px" src="http://iv.vu818.com/img/vu618.png" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AppContentThree",
    props: ["caseData", "caseClick"],

    methods: {
        goMore() {
            this.$router.push("/case");
        },
    },
    mounted() {
        function show() {
            let logosBox = document.getElementById("logosBox");
            let acTLeftBox = document.getElementsByClassName("acTLeftBox");
            if (acTLeftBox[0].getBoundingClientRect().top < 358) {
                logosBox.className = "scaleClass";
                logosBox.style.opacity = "1";
            } else {
                logosBox.className = "";
                logosBox.style.opacity = "0";
            }
            window.removeEventListener("scroll", show);
        }
        window.addEventListener("scroll", show);
        this.$AOS.init({
            offset: 200,
            duration: 600,
            easing: "ease-in-sine",
            delay: 100,
        });
        new this.$wow.WOW().init({
            boxClass: "wow",
            animateClass: "animate__animated",
            offset: 0,
            mobile: true,
            live: true,
        });
    },
};
</script>

<style lang="less" scoped>
@keyframes setScale {
    0% {
        opacity: 0;
        transform: scale(0);
    }
    50% {
        opacity: 0.9;
        transform: scale(1.1);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}
#logosBox {
    opacity: 0;
    position: absolute;
    top: 0;
}
.scaleClass {
    animation: setScale 2s 1;
}
.imageBox {
    width: 45px;
    height: 45px;
    overflow: hidden;
    position: absolute;
}
.imageBox img {
    width: 45em;
}
.appContentThree {
    width: 100%;
    height: 700px;
    background-color: rgb(62, 198, 174);
    .acTBox {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        // 左边信息
        .acTLeftBox {
            width: 50%;
            height: 90%;
            float: left;
            margin-top: 5%;
            margin-left: 5%;
            // 顶部文字
            .acTTopText {
                width: 80%;
                height: 200px;
                color: white;
                .acTBig {
                    width: 100%;
                    height: 30px;
                    line-height: 30px;
                    font-size: 25px;
                }
                .acTSmall {
                    width: 100%;
                    height: 20px;
                    line-height: 20px;
                    font-size: 15px;
                    margin-top: 5px;
                }
                .acTList {
                    width: 100%;
                    height: 10px;
                    margin-top: 20px;
                    font-size: 12px;
                }
            }
            // 图标列表
            .acTBottomList {
                width: 70%;
                height: 180px;
                margin-top: 20px;
                color: white;
                .ablText {
                    width: 100%;
                    height: 20px;
                    line-height: 20px;
                    font-weight: 900;
                    font-size: 20px;
                }
                ul {
                    width: 100%;
                    height: 130px;
                    margin-top: 20px;
                    display: flex;
                    flex-wrap: wrap;
                    .ablLi {
                        width: 16%;
                        height: 50%;
                        // margin-left: 1%;
                        .alIcon {
                            width: 45px;
                            height: 70%;
                            margin: 0 auto;
                            overflow: hidden;
                            position: relative;
                            .aiImg {
                                position: absolute;
                                top: -225px;
                                left: -416px;
                            }
                            .aiImg2 {
                                position: absolute;
                                top: -225px;
                                left: -466px;
                            }
                            .aiImg3 {
                                position: absolute;
                                top: -225px;
                                left: -517px;
                            }
                            .aiImg4 {
                                position: absolute;
                                top: -225px;
                                left: -567px;
                            }
                            .aiImg5 {
                                position: absolute;
                                top: -225px;
                                left: -618px;
                            }
                            .aiImg6 {
                                position: absolute;
                                top: -225px;
                                left: -672px;
                            }
                            .aiImg7 {
                                position: absolute;
                                top: -225px;
                                left: -724px;
                            }
                            .aiImg8 {
                                position: absolute;
                                top: -225px;
                                left: -772px;
                            }
                        }
                        .iconfont {
                            width: 100%;
                            height: 50%;
                            font-size: 30px;
                            text-align: center;
                            display: block;
                        }
                        .ablP {
                            width: 100%;
                            height: 20%;
                            text-align: center;
                            font-size: 14px;
                        }
                    }
                }
            }
            // 我们的案例
            .acTAlList {
                width: 70%;
                height: 110px;
                margin-top: 20px;
                color: white;
                .aalText {
                    width: 100%;
                    height: 20px;
                    line-height: 20px;
                    font-weight: 900;
                    font-size: 20px;
                }
                ul {
                    width: 100%;
                    height: 50px;
                    margin-top: 10px;
                    display: flex;
                    justify-content: left;
                    .alLi {
                        width: 51px;
                        height: 51px;
                        cursor: pointer;
                        transition: 0.5s;
                        border-radius: 10px;
                        margin-right: 5px;
                        overflow: hidden;
                        .alImg {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .alLi:hover {
                        transform: translateY(-5px);
                        box-shadow: 1px 1px 10px 1px gray;
                    }
                }
            }
            // 查看更多案例
            .ct2More {
                width: 160px;
                height: 80px;
                text-align: center;
                margin-top: 10px;
                cursor: pointer;
                font-weight: bold;
                .cmp {
                    width: 100%;
                    height: 30px;
                    line-height: 30px;
                    padding-top: 5px;
                    color: white;
                }
                .cmTop {
                    width: 100%;
                    position: relative;
                    transition: 0.5s;
                }
                .cmTop::before {
                    border-width: 1px 0 0 1px;
                    border-color: white;
                    left: 20px;
                    content: "";
                    position: absolute;
                    width: 7px;
                    height: 7px;
                    border-style: solid;

                    transition-duration: 0.3s;

                    transform: translateZ(0);
                }
                .cmTop::after {
                    border-width: 1px 1px 0 0;
                    border-color: white;
                    right: 20px;
                    content: "";
                    position: absolute;
                    width: 7px;
                    height: 7px;
                    border-style: solid;

                    transition-duration: 0.3s;

                    transform: translateZ(0);
                }
                .cmBottom {
                    width: 100%;
                    position: relative;
                    transition: 0.5s;
                }
                .cmBottom::before {
                    border-width: 0 0 1px 1px;
                    border-color: white;
                    left: 20px;
                    content: "";
                    position: absolute;
                    width: 7px;
                    height: 7px;
                    border-style: solid;

                    transition-duration: 0.3s;

                    transform: translateZ(0);
                }
                .cmBottom::after {
                    border-width: 0 1px 1px 0;
                    border-color: white;
                    right: 20px;
                    content: "";
                    position: absolute;
                    width: 7px;
                    height: 7px;
                    border-style: solid;
                    transition-duration: 0.3s;
                    transform: translateZ(0);
                }
            }
            .ct2More:hover {
                .cmTop {
                    width: 180px;
                    transform: translateY(-10px) translateX(-10px);
                }
                .cmBottom {
                    width: 180px;
                    transform: translateY(10px) translateX(-10px);
                }
            }
            // .acTMoreBtn {
            //   width: 150px;
            //   height: 30px;
            //   line-height: 30px;
            //   margin-top: 20px;
            //   text-align: center;
            //   border-radius: 10px;
            //   color: white;
            //   cursor: pointer;
            //   border: 1px solid white;
            //   transition: 0.5s;
            // }
            // .acTMoreBtn:hover {
            //   background-color: #fff;
            //   color: rgb(198, 236, 167);
            // }
        }
        // 右边图片
        .acTRightImg {
            width: 440px;
            height: 420px;
            float: left;
            margin-top: 15%;
            margin-right: 5%;
            position: relative;
            .actBg {
                width: 100%;
                height: 100%;
                position: relative;
                overflow: hidden;
                img {
                    position: absolute;
                    bottom: -470px;
                    right: 35px;
                }
            }
            .acTImg {
                width: 60%;
                height: 90%;
                margin-top: 20px;
                position: absolute;
                top: 0;
                left: 20%;
            }
        }
    }
}
</style>
