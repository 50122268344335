<template>
    <div class="appContentOne">
        <div class="acoBox">
            <!-- 标题 -->
            <div class="acoTopText wow animate__animated animate__flipInX">
                <img src="http://iv.vu818.com/img/page1_3.png" alt="" />
            </div>
            <!-- 中间图 -->
            <div class="acoCenter">
                <img
                    src="http://iv.vu818.com/img/page1_2.png"
                    alt=""
                    class="actImg1 wow animate__animated animate__slideInDown"
                />
                <img src="http://iv.vu818.com/img/page1_1.png" alt="" class="actImg2 wow animate__animated animate__flipInY" />
            </div>
            <!-- 小地点 -->
            <div class="acoBottomLi">
                <ul>
                    <li class="acoLi wow animate__animated animate__bounceInUp">
                        <a href="#ac8">
                            <img class="acoImg" src="https://iv.vu818.com/img/vu818.png" alt="" />
                        </a>
                    </li>
                    <li class="acoLi wow animate__animated animate__bounceInUp" data-wow-delay="0.1s">
                        <a href="#ac3">
                            <img class="acoImg" src="http://iv.vu818.com/img/vu818.png" alt="" />
                        </a>
                    </li>
                    <li class="acoLi wow animate__animated animate__bounceInUp" data-wow-delay="0.2s">
                        <a href="#ac9">
                            <img class="acoImg" src="http://iv.vu818.com/img/vu818.png" alt="" />
                        </a>
                    </li>
                    <li class="acoLi wow animate__animated animate__bounceInUp" data-wow-delay="0.3s">
                        <a href="#ac7">
                            <img class="acoImg" src="http://iv.vu818.com/img/vu818.png" alt="" />
                        </a>
                    </li>
                    <li class="acoLi wow animate__animated animate__bounceInUp" data-wow-delay="0.4s">
                        <a href="#ac6">
                            <img class="acoImg" src="http://iv.vu818.com/img/vu818.png" alt="" />
                        </a>
                    </li>
                    <li class="acoLi wow animate__animated animate__bounceInUp" data-wow-delay="0.5s">
                        <a href="#ac5">
                            <img class="acoImg" src="http://iv.vu818.com/img/vu818.png" alt="" />
                        </a>
                    </li>
                    <li class="acoLi wow animate__animated animate__bounceInUp" data-wow-delay="0.6s">
                        <a href="#ac4">
                            <img class="acoImg" src="http://iv.vu818.com/img/vu818.png" alt="" />
                        </a>
                    </li>
                    <li class="acoLi wow animate__animated animate__bounceInUp" data-wow-delay="0.7s">
                        <a href="#ac2">
                            <img class="acoImg" src="http://iv.vu818.com/img/vu818.png" alt="" />
                        </a>
                    </li>
                    <li class="acoLi wow animate__animated animate__bounceInUp" data-wow-delay="0.8s">
                        <a href="#ac10">
                            <img class="acoImg" src="http://iv.vu818.com/img/vu818.png" alt="" />
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AppContentOne",
    data() {
        return {};
    },
    mounted() {
        this.$AOS.init({
            offset: 200,
            duration: 600,
            easing: "ease-in-sine",
            delay: 100,
        });
        new this.$wow.WOW().init({
            boxClass: "wow",
            animateClass: "animate__animated",
            offset: 0,
            mobile: true,
            live: true,
        });
    },
};
</script>

<style lang="less" scoped>
.appContentOne {
    width: 100%;
    height: 700px;
    background: url("http://iv.vu818.com/img/bg_1.jpg") no-repeat center;
    .acoBox {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        position: relative;
        // 标题
        .acoTopText {
            width: 420px;
            // height: 75px;
            margin: 0 auto;
            padding-top: 50px;
            img {
                width: 100%;
                height: 100%;
                z-index: 7;
                position: relative;
            }
        }
        // 中间图
        .acoCenter {
            width: 650px;
            height: 300px;
            margin: 0 auto;
            position: relative;
            .actImg1 {
                width: 600px;
                height: 300px;
                position: absolute;
                bottom: 20%;
                left: 0;
                z-index: 6;
            }
            .actImg2 {
                width: 400px;
                height: 110px;
                position: absolute;
                bottom: 0%;
                left: 20%;
                z-index: 5;
            }
        }
        // 小地点
        .acoBottomLi {
            width: 100%;
            height: 250px;
            ul {
                width: 100%;
                height: 100%;
                position: relative;
                .acoLi {
                    width: 95px;
                    height: 90px;
                    transition: 0.5s;
                    z-index: 19;
                }
                .acoLi:nth-child(1) {
                    position: absolute;
                    top: 28%;
                    left: 8%;
                    overflow: hidden;
                    width: 110px;
                    height: 95px;
                    .acoImg {
                        position: absolute;
                        top: -1624px;
                        left: 0px;
                    }
                }
                .acoLi:nth-child(2) {
                    position: absolute;
                    top: 9%;
                    left: 22%;
                    overflow: hidden;
                    .acoImg {
                        position: absolute;
                        top: -411px;
                        left: -482px;
                    }
                }
                .acoLi:nth-child(3) {
                    position: absolute;
                    top: 2%;
                    left: 35%;
                    overflow: hidden;
                    .acoImg {
                        position: absolute;
                        top: -410px;
                        left: -570px;
                    }
                }
                .acoLi:nth-child(4) {
                    position: absolute;
                    top: 17%;
                    left: 45%;
                    overflow: hidden;
                    .acoImg {
                        position: absolute;
                        top: -416px;
                        left: -662px;
                    }
                }
                .acoLi:nth-child(5) {
                    position: absolute;
                    top: -15%;
                    left: 55%;
                    overflow: hidden;
                    .acoImg {
                        position: absolute;
                        top: -416px;
                        left: -755px;
                    }
                }
                .acoLi:nth-child(6) {
                    position: absolute;
                    top: -23%;
                    right: 25%;
                    overflow: hidden;
                    .acoImg {
                        position: absolute;
                        top: -416px;
                        left: -850px;
                    }
                }
                .acoLi:nth-child(7) {
                    width: 90px;
                    position: absolute;
                    bottom: 78%;
                    right: 10%;
                    overflow: hidden;
                    .acoImg {
                        position: absolute;
                        top: -416px;
                        left: -943px;
                    }
                }
                .acoLi:nth-child(8) {
                    position: absolute;
                    bottom: 30%;
                    right: 24%;
                    overflow: hidden;
                    .acoImg {
                        position: absolute;
                        top: -416px;
                        left: -1026px;
                    }
                }
                .acoLi:nth-child(9) {
                    width: 125px;
                    position: absolute;
                    top: 39%;
                    right: 6%;
                    overflow: hidden;
                    .acoImg {
                        position: absolute;
                        top: -416px;
                        left: -1120px;
                    }
                }
                .acoLi:hover {
                    transform: translateY(-10px);
                }
            }
        }
    }
}
</style>
