<template>
    <div class="appContentTen">
        <div class="actenBox">
            <!-- 左边图片 -->
            <div class="actenLeftImg">
                <div class="actBg wow animate__animated animate__slideInUp" data-wow-delay="0.2s">
                    <img src="http://iv.vu818.com/img/vu818.png" alt="" />
                </div>
                <img
                    class="actenImg wow animate__animated animate__slideInUp"
                    src="http://iv.vu818.com/img/page10_3.png"
                    alt=""
                />
                <img
                    class="actenImg wow animate__animated animate__slideInUp"
                    src="http://iv.vu818.com/img/page10_1.png"
                    alt=""
                />
                <img
                    class="actenImg wow animate__animated animate__slideInUp"
                    src="http://iv.vu818.com/img/page10_2.png"
                    alt=""
                />
            </div>
            <!-- 右边信息 -->
            <div class="actenRightBox">
                <!-- 顶部文字 -->
                <div class="actenTopText">
                    <p class="actenBig wow animate__animated animate__slideInRight">行业门户App</p>
                    <p class="actenSmall wow animate__animated animate__slideInRight" data-wow-delay="0.1s">
                        移动网罗大众，门户更显不同
                    </p>
                    <p class="actenList wow animate__animated animate__slideInRight" data-wow-delay="0.2s">
                        定制App开发，支持IOS/安卓双系统
                    </p>
                    <p class="actenList wow animate__animated animate__slideInRight" data-wow-delay="0.3s">
                        支持商家端/客户端 双端开发
                    </p>
                    <p class="actenList wow animate__animated animate__slideInRight" data-wow-delay="0.4s">
                        支持物联网硬件对接/软件对接
                    </p>
                    <p class="actenList wow animate__animated animate__slideInRight" data-wow-delay="0.5s">
                        支持App+Pc+Web+微信四合一全网开发
                    </p>
                </div>
                <!-- 图标列表 -->
                <div class="actenBottomList">
                    <div class="ablText">功能</div>
                    <ul>
                        <li class="ablLi wow animate__animated animate__slideInRight" data-wow-delay="0.6s">
                            <div class="alIcon">
                                <img src="http://iv.vu818.com/img/vu618.png" alt="" class="aiImg" />
                            </div>
                            <!-- <i class="iconfont icon-jiaoyu"></i> -->
                            <p class="ablP">广告管理</p>
                        </li>
                        <li class="ablLi wow animate__animated animate__slideInRight" data-wow-delay="0.6s">
                            <div class="alIcon">
                                <img src="http://iv.vu818.com/img/vu618.png" alt="" class="aiImg2" />
                            </div>
                            <!-- <i class="iconfont icon-jiaoyu"></i> -->
                            <p class="ablP">广告管理</p>
                        </li>
                        <li class="ablLi wow animate__animated animate__slideInRight" data-wow-delay="0.6s">
                            <div class="alIcon">
                                <img src="http://iv.vu818.com/img/vu618.png" alt="" class="aiImg3" />
                            </div>
                            <!-- <i class="iconfont icon-jiaoyu"></i> -->
                            <p class="ablP">广告管理</p>
                        </li>
                        <li class="ablLi wow animate__animated animate__slideInRight" data-wow-delay="0.6s">
                            <div class="alIcon">
                                <img src="http://iv.vu818.com/img/vu618.png" alt="" class="aiImg4" />
                            </div>
                            <!-- <i class="iconfont icon-jiaoyu"></i> -->
                            <p class="ablP">广告管理</p>
                        </li>
                        <li class="ablLi wow animate__animated animate__slideInRight" data-wow-delay="0.6s">
                            <div class="alIcon">
                                <img src="http://iv.vu818.com/img/vu618.png" alt="" class="aiImg5" />
                            </div>
                            <!-- <i class="iconfont icon-jiaoyu"></i> -->
                            <p class="ablP">广告管理</p>
                        </li>
                        <li class="ablLi wow animate__animated animate__slideInRight" data-wow-delay="0.6s">
                            <div class="alIcon">
                                <img src="http://iv.vu818.com/img/vu618.png" alt="" class="aiImg6" />
                            </div>
                            <!-- <i class="iconfont icon-jiaoyu"></i> -->
                            <p class="ablP">广告管理</p>
                        </li>
                        <li class="ablLi wow animate__animated animate__slideInRight" data-wow-delay="0.6s">
                            <div class="alIcon">
                                <img src="http://iv.vu818.com/img/vu618.png" alt="" class="aiImg7" />
                            </div>
                            <!-- <i class="iconfont icon-jiaoyu"></i> -->
                            <p class="ablP">广告管理</p>
                        </li>
                        <li class="ablLi wow animate__animated animate__slideInRight" data-wow-delay="0.6s">
                            <div class="alIcon">
                                <img src="http://iv.vu818.com/img/vu618.png" alt="" class="aiImg8" />
                            </div>
                            <!-- <i class="iconfont icon-jiaoyu"></i> -->
                            <p class="ablP">广告管理</p>
                        </li>
                    </ul>
                </div>
                <!-- 我们的案例 -->
                <div class="actenAlList">
                    <div class="aalText">我们的案例</div>
                    <el-carousel height="100px" arrow="never" :autoplay="true" :interval="2000" indicator-position="none">
                        <el-carousel-item v-for="(item, i) in caseData" :key="i">
                            <ul>
                                <li class="alLi" v-for="el in item" :key="el.id" @click="goDetail(el.id)">
                                    <img :src="el.logo" class="alImg" />
                                </li>
                            </ul>
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <!-- 查看更多案例 -->
                <!-- <div class="actenMoreBtn" @click="goMore">查看更多案例</div> -->
                <div class="ct2More" @click="goMore">
                    <div class="cmTop"></div>
                    <p class="cmp">查看更多案例</p>
                    <div class="cmBottom"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AppContentTen",
    props: ["caseData", "caseClick"],

    methods: {
        goDetail() {
            this.$router.push("/appDetail");
        },
        goMore() {
            this.$router.push("/case");
        },
    },
    mounted() {
        this.$AOS.init({
            offset: 200,
            duration: 600,
            easing: "ease-in-sine",
            delay: 100,
        });
        new this.$wow.WOW().init({
            boxClass: "wow",
            animateClass: "animate__animated",
            offset: 0,
            mobile: true,
            live: true,
        });
    },
};
</script>

<style lang="less" scoped>
.appContentTen {
    width: 100%;
    height: 700px;
    background-color: rgb(125, 94, 232);
    .actenBox {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        // 左边图片
        .actenLeftImg {
            width: 440px;
            height: 420px;
            float: left;
            margin-top: 15%;
            margin-left: 5%;
            position: relative;
            .actBg {
                width: 100%;
                height: 100%;
                position: relative;
                overflow: hidden;
                img {
                    position: absolute;
                    bottom: -471px;
                    right: 35px;
                }
            }
            .actenImg:nth-child(2) {
                width: 60%;
                position: absolute;
                top: 36%;
                left: 20%;
                z-index: 3;
            }
            .actenImg:nth-child(3) {
                width: 94%;
                position: absolute;
                top: 13%;
                left: 1%;
            }
            .actenImg:nth-child(4) {
                width: 88%;
                height: 13%;
                position: absolute;
                top: 85%;
                left: 7%;
                z-index: 1;
            }
        }
        // 右边信息
        .actenRightBox {
            width: 50%;
            height: 90%;
            float: right;
            margin-top: 5%;
            // 顶部文字
            .actenTopText {
                width: 80%;
                height: 200px;
                color: white;
                .actenBig {
                    width: 100%;
                    height: 30px;
                    line-height: 30px;
                    font-size: 25px;
                }
                .actenSmall {
                    width: 100%;
                    height: 20px;
                    line-height: 20px;
                    font-size: 15px;
                }
                .actenList {
                    width: 100%;
                    height: 10px;
                    margin-top: 20px;
                    font-size: 15px;
                }
            }
            // 图标列表
            .actenBottomList {
                width: 70%;
                height: 180px;
                margin-top: 20px;
                color: white;
                .ablText {
                    width: 100%;
                    height: 20px;
                    line-height: 20px;
                    font-weight: 900;
                    font-size: 20px;
                }
                ul {
                    width: 100%;
                    height: 130px;
                    margin-top: 20px;
                    display: flex;
                    flex-wrap: wrap;
                    .ablLi {
                        width: 16%;
                        height: 50%;
                        // margin-left: 1%;
                        .alIcon {
                            width: 45px;
                            height: 70%;
                            margin: 0 auto;
                            overflow: hidden;
                            position: relative;
                            .aiImg {
                                position: absolute;
                                top: -398px;
                                left: -103px;
                            }
                            .aiImg2 {
                                position: absolute;
                                top: -395px;
                                left: -153px;
                            }
                            .aiImg3 {
                                position: absolute;
                                top: -398px;
                                left: -202px;
                            }
                            .aiImg4 {
                                position: absolute;
                                top: -398px;
                                left: -255px;
                            }
                            .aiImg5 {
                                position: absolute;
                                top: -398px;
                                left: -306px;
                            }
                            .aiImg6 {
                                position: absolute;
                                top: -398px;
                                left: -360px;
                            }
                            .aiImg7 {
                                position: absolute;
                                top: -398px;
                                left: -417px;
                            }
                            .aiImg8 {
                                position: absolute;
                                top: -398px;
                                left: -462px;
                            }
                        }
                        .iconfont {
                            width: 100%;
                            height: 50%;
                            font-size: 30px;
                            text-align: center;
                            display: block;
                        }
                        .ablP {
                            width: 100%;
                            height: 20%;
                            text-align: center;
                            font-size: 14px;
                        }
                    }
                }
            }
            // 我们的案例
            .actenAlList {
                width: 70%;
                height: 110px;
                margin-top: 20px;
                color: white;
                .aalText {
                    width: 100%;
                    height: 20px;
                    line-height: 20px;
                    font-weight: 900;
                    font-size: 20px;
                }
                ul {
                    width: 100%;
                    height: 50px;
                    margin-top: 10px;
                    display: flex;
                    justify-content: left;
                    .alLi {
                        width: 51px;
                        height: 51px;
                        cursor: pointer;
                        transition: 0.5s;
                        border-radius: 10px;
                        margin-right: 5px;
                        overflow: hidden;
                        .alImg {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .alLi:hover {
                        transform: translateY(-5px);
                        box-shadow: 1px 1px 10px 1px gray;
                    }
                }
            }
            // 查看更多案例
            // .actenMoreBtn {
            //   width: 150px;
            //   height: 30px;
            //   line-height: 30px;
            //   margin-top: 20px;
            //   text-align: center;
            //   border-radius: 10px;
            //   color: white;
            //   cursor: pointer;
            //   border: 1px solid white;
            //   transition: 0.5s;
            // }
            // .actenMoreBtn:hover {
            //   background-color: #fff;
            //   color: rgb(199, 133, 236);
            // }
            .ct2More {
                width: 160px;
                height: 80px;
                text-align: center;
                margin-top: 10px;
                cursor: pointer;
                font-weight: bold;
                .cmp {
                    width: 100%;
                    height: 30px;
                    line-height: 30px;
                    padding-top: 5px;
                    color: white;
                }
                .cmTop {
                    width: 100%;
                    position: relative;
                    transition: 0.5s;
                }
                .cmTop::before {
                    border-width: 1px 0 0 1px;
                    border-color: white;
                    left: 20px;
                    content: "";
                    position: absolute;
                    width: 7px;
                    height: 7px;
                    border-style: solid;

                    transition-duration: 0.3s;

                    transform: translateZ(0);
                }
                .cmTop::after {
                    border-width: 1px 1px 0 0;
                    border-color: white;
                    right: 20px;
                    content: "";
                    position: absolute;
                    width: 7px;
                    height: 7px;
                    border-style: solid;

                    transition-duration: 0.3s;

                    transform: translateZ(0);
                }
                .cmBottom {
                    width: 100%;
                    position: relative;
                    transition: 0.5s;
                }
                .cmBottom::before {
                    border-width: 0 0 1px 1px;
                    border-color: white;
                    left: 20px;
                    content: "";
                    position: absolute;
                    width: 7px;
                    height: 7px;
                    border-style: solid;

                    transition-duration: 0.3s;

                    transform: translateZ(0);
                }
                .cmBottom::after {
                    border-width: 0 1px 1px 0;
                    border-color: white;
                    right: 20px;
                    content: "";
                    position: absolute;
                    width: 7px;
                    height: 7px;
                    border-style: solid;
                    transition-duration: 0.3s;
                    transform: translateZ(0);
                }
            }
            .ct2More:hover {
                .cmTop {
                    width: 180px;
                    transform: translateY(-10px) translateX(-10px);
                }
                .cmBottom {
                    width: 180px;
                    transform: translateY(10px) translateX(-10px);
                }
            }
        }
    }
}
</style>
